import React from "react";
import { H6, Image } from "../../AbstractElements";

export const DatatableElementRow = (animal) => {
  const {
    id,
    imageDownloadUrl,
    animalName,
    animalAskingPrice,
    available,
    animalDispatchDate,
    animalWeight,
    animalColor,
    description,
    animalCollectionLocation,
    animalYearsAge,
    animalSickness,
  } = animal;

  const style = {
    width: 40,
    height: 40,
  };

  const style2 = {
    width: 60,
    fontSize: 13,
    padding: 3,
  };

  const class_name = available ? "font-sucess" : "font-danger";
  const result = available ? "in Stock" : "Out of Stock";

  return {
    id: id,
    name: animalName,
    image: (
      <Image attrImage={{ src: imageDownloadUrl, style: style, alt: "" }} />
    ),
    Details: (
      <div>
        <H6>{animalName}</H6>
        <span>{description}</span>
      </div>
    ),
    ask: animalAskingPrice,
    collection_address: animalCollectionLocation,
    stock: <div className={class_name}>{result}</div>,
    dispatch_date: animalDispatchDate,
    weight: animalWeight,
    age: animalYearsAge,
    color: animalColor,
    sickness: animalSickness,
  };
};
