import React, { useEffect, useState } from "react";
import Context from "./index";
import {
  addAnimal,
  removedAnimal,
  deleteAnimal,
  listAnimal,
  fetchAllAnimals,
} from "../../Services/Animal-Firebase.Service";

const AnimalProvider = (props) => {
  const [allAnimals, setAllAnimals] = useState([]);
  const [selectedAnimal, setSelectedAnimal] = useState({});
  const [isVertical, setIsVertical] = useState(false);
  const symbol = "$";

  const addNewAnimal = (animal) => {
    addAnimal(animal);
    setAllAnimals([...allAnimals, animal]);
  };

  const handleRemoveAnimal = (animalID, otherData) => {
    const removeAnimal = async (id, animalData) => {
      try {
        await removedAnimal(id, animalData);
      } catch (error) {
        console.log(error);
      }
    };
    removeAnimal(animalID, otherData);
    const updatedAnimals = allAnimals.filter((currentAnimal) => (currentAnimal.id !== animalID));

    setAllAnimals(updatedAnimals);
  };

  const handleListAnimal = (animalID, otherData) => {
    const enListAnimal = async (id, animalData) => {
      try {
        await listAnimal(id, animalData);
      } catch (error) {
        console.log(error);
      }
    };
    enListAnimal(animalID, otherData);
    const updatedAnimals = allAnimals.filter((currentAnimal) => (currentAnimal.id !== animalID));

    setAllAnimals(updatedAnimals);
  };

  const handleDeleteAnimal = (animalID) => {
    const deleteData = async (id) => {
      try {
        await removedAnimal(id);
      } catch (error) {
        console.error(error);
      }
    };

    deleteData(animalID);
    const updatedAnimals = allAnimals.filter((currentAnimal) => (currentAnimal.id !== animalID));

    setAllAnimals(updatedAnimals);
  };

  const pickSelectedItem = (id) => {
    const foundAnimal = allAnimals.find((animal) => animal.id === id);
    setSelectedAnimal(foundAnimal);
  };

  const handleFetchData = () => {
    const fetchData = async () => {
      try {
        const animals = await fetchAllAnimals();
        // Do something with the animals array:
        // - Update state
        setAllAnimals(animals);
        // - Display the data in your component
        // ...
      } catch (error) {
        console.error("Error fetching animals:", error);
        // Handle error appropriately
      }
    };

    fetchData();
  };

  return (
    <Context.Provider
      value={{
        ...props,
        allAnimals,
        selectedAnimal,
        isVertical,
        symbol,
        pickSelectedItem,
        handleListAnimal,
        handleFetchData: handleFetchData,
        addNewAnimal: addNewAnimal,
        handleRemoveAnimal: handleRemoveAnimal,
        handleDeleteAnimal: handleDeleteAnimal,

      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default AnimalProvider;
