import React, { Fragment, useContext } from 'react';
import { Truck, Clock } from 'react-feather';
import { Card, CardBody, Col, Label, Input, Media, } from 'reactstrap';
import { H4, H5, LI, P, UL } from '../../../AbstractElements';
import AnimalContext from "../../../_helper/Animal";

const AnimalProperties = () => {
  const {selectedAnimal} = useContext(AnimalContext);
  return (
    <Fragment>
      <Col xl="4" md="6" className="box-col-5 xl-50 proorder-lg-1">
        <Card>
          <CardBody>
            <div className="filter-block">
              <H4>{'Brand'}</H4>
              <UL attrUL={{ className: 'simple-list' }}>
                <LI>
                  <div className="form-check">
                    <Input className="form-check-input" id="Raymond" type="checkbox" value="" defaultChecked readOnly/>
                    <Label className="form-check-label" htmlFor="Raymond">{selectedAnimal.animalBreed}</Label>
                  </div>
                </LI>
                <LI>
                  <div className="form-check">
                    <Input className="form-check-input" id="Pepe-Jeans" type="checkbox" value="" defaultChecked readOnly/>
                    <Label className="form-check-label" htmlFor="Pepe-Jeans">{selectedAnimal.animalColor}</Label>
                  </div>
                </LI>
                <LI>
                  <div className="form-check">
                    <Input className="form-check-input" id="Celio" type="checkbox" value="" defaultChecked readOnly/>
                    <Label className="form-check-label" htmlFor="Celio">{selectedAnimal.animalWeight} Kgs</Label>
                  </div>
                </LI>
                <LI>
                  <div className="form-check">
                    <Input className="form-check-input" id="aime" type="checkbox" value="" defaultChecked  readOnly/>
                    <Label className="form-check-label" htmlFor="aime">{selectedAnimal.animalYearsAge} years</Label>
                  </div>
                </LI>
                <LI>
                  <div className="form-check">
                    <Input className="form-check-input" id="aliff" type="checkbox" value="" defaultChecked readOnly/>
                    <Label className="form-check-label" htmlFor="aliff">{selectedAnimal.animalBreed}</Label>
                  </div>
                </LI>
              </UL>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <div className="collection-filter-block">
              <UL attrUL={{ className: 'simple-list pro-services' }}>
                <LI>
                  <Media><Truck />
                    <Media body >
                      <H5>{selectedAnimal.animalCollectionLocation}</H5>
                      <P>{'Designated point of collection'}</P>
                    </Media>
                  </Media>
                </LI>
                <LI>
                  <Media><Clock />
                    <Media body >
                      <H5>{selectedAnimal.animalDispatchDate}</H5>
                      <P>{'Chosen date for collection'}</P>
                    </Media>
                  </Media>
                </LI>
              </UL>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};
export default AnimalProperties;