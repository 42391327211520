
import React, { Fragment, useState } from "react";
import { Col, FormGroup, Input, Label } from "reactstrap";
import { AllAnimalBreeds, AnimalAgeGroups } from "../../../Constant";

const MainPriority = (props) => {
  const { AnimalBreedLabel = "", AnimalAgeLabel = "", register = '', errors = '', animalType } = props;
  const currentBreedsList = AllAnimalBreeds[animalType];
  const currentAgeGroupList = AnimalAgeGroups[animalType];


  return (
    <Fragment>
      {AnimalAgeLabel ? (
        <Col sm="4">
          <FormGroup>
            <Label>{AnimalAgeLabel}</Label>
            <select
              type="select"
              className="form-control digits"
              placeholder="Select Age"
              {...register('animalAge', { required: true })}
            >
              {currentAgeGroupList.map((ageGroupName, index) => {
                return <option key={index} value={ageGroupName}>{ageGroupName}</option>;
              })}
            </select>
            <span style={{ color: 'red' }}>{errors.animalAge && 'Animal Age is required'} </span>
          </FormGroup>
        </Col>
      ) : (
        <Col sm="4">
          <FormGroup>
            <Label>{AnimalBreedLabel}</Label>
            <select
              type="select"
              placeholder="Select Breed"
              className="form-control digits"
              {...register('animalBreed', { required: true })}
            >
              {currentBreedsList.map((breedName, index) => {
                return <option key={index} value={breedName}>{breedName}</option>;
              })}
            </select>
            <span style={{ color: 'red' }}>{errors.animalBreed && 'Animal Breed is required'} </span>
          </FormGroup>
        </Col>
      )}
    </Fragment>
  );
};
export default MainPriority;
