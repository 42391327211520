import { dbFirestore } from "../Config/Config";

export const addAnimal = (animal) => {
  try {
    dbFirestore.collection("animals").add(animal);
  } catch (error) {
    console.error("Error adding animal:", error);
  }
};

export const removedAnimal = async (animalID, otherData) => {
  try {
    await dbFirestore
      .collection("animals")
      .doc(animalID)
      .set({ ...otherData, status: "deleted" });
      
  } catch (error) {
    console.log(error);
  }
};

export const listAnimal = async (animalID, otherData) => {
  try {
    await dbFirestore
      .collection("animals")
      .doc(animalID)
      .set({ ...otherData, status: "listed" });
  } catch (error) {
    console.log(error);
  }
};

export const deleteAnimal = async (animalID) => {
  try {
    await dbFirestore.collection("animals").doc(animalID).delete();
  } catch (error) {
    console.log();
  }
};

export const fetchAllAnimals = async () => {
  try {
    const querySnapshot = await dbFirestore
      .collection("animals")
      .where("status", "!=", "deleted")
      .get();

    const animalData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      img: doc.get("img"),
      animalBreed: doc.get("animalBreed"),
      animalType: doc.get("animalType"),
      animalAskingPrice: parseFloat(doc.get("animalAskingPrice")).toFixed(2),
      animalCollectionLocation: doc.get("animalCollectionLocation"),
      animalColor: doc.get("animalColor"),
      animalDispatchDate: doc.get("animalDispatchDate"),
      animalName: doc.get("animalName"),
      animalSickness: doc.get("animalSickness"),
      animalWeight: parseInt(doc.get("animalWeight")),
      animalYearsAge: parseInt(doc.get("animalYearsAge")),
      description: doc.get("description"),
      imageDownloadUrl: doc.get("imageDownloadUrl"),
      auctioned: doc.get("auctioned"),
      available: doc.get("available"),
      status: doc.get("status"),
      variants: doc.get("variants"),
      supplierName: doc.get("supplierName"),
      supplierNumber: doc.get("supplierNumber"),
    }));

    return animalData;
  } catch (error) {
    console.error("Error fetching animals:", error);
    // Handle error appropriately, e.g., return an empty array or throw an error
    return []; // Or throw error if required
  }
};
