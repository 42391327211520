import React, { Fragment } from 'react'
import { Breadcrumbs } from '../../../AbstractElements'
import AnimalDetailsPageContain from '../../../Component/Animals/AnimalDetailsPageContain';

const AnimalsDetailsPage = () => {
   
    return (
        <Fragment>
            <Breadcrumbs parent='Animals' title='Animal Details' />
            <AnimalDetailsPageContain />
        </Fragment>
    )
}

export default AnimalsDetailsPage;