import React, { Fragment, useContext, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Form, Input, Row } from 'reactstrap';
import { H6, Image } from '../../../AbstractElements';
import FilterContext from '../../../_helper/ecommerce/filter';
import Category from '../filters/Category';
import PrizeRange from '../filters/PrizeRange';
import banner from '../../../assets/images/ecommerce/banner.jpg';
import { Filters } from '../../../Constant';

const ProductSidebar = () => {
  const { filterSearchBy, filterSidebar } = useContext(FilterContext);
  const [sidebaron, setSidebaron] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState('');
  const onClickFilter = () => {
    if (sidebaron) {
      setSidebaron(false);
      document.querySelector('.product-wrapper').classList.add('sidebaron');
    } else {
      setSidebaron(true);
      document.querySelector('.product-wrapper').classList.remove('sidebaron');
    }
  };
  const handleSearchKeyword = (keyword) => {
    setSearchKeyword(keyword);
    filterSearchBy(searchKeyword);
  };
  return (
    <Fragment>
      <Row>
        <Col md="12">
          <div className="pro-filter-sec">
            <div className={`product-sidebar ${filterSidebar ? '' : 'open'}`}>
              <div className="filter-section">
                <Card>
                  <CardHeader>
                    <H6 attrH6={{ className: 'mb-0 f-w-600' }}>{Filters}
                      <span className="pull-right">
                        <i className="fa fa-chevron-down toggle-data"
                          onClick={onClickFilter}>
                        </i>
                      </span>
                    </H6>
                  </CardHeader>
                  <div className="left-filter">
                    <CardBody className="filter-cards-view animate-chk">
                      <Category />
                      <PrizeRange />
                      <div className="product-filter text-center">
                        <Image attrImage={{ className: 'img-fluid banner-product', src: `${banner}`, alt: '' }} />
                      </div>
                    </CardBody>
                  </div>
                </Card>
              </div>
            </div>
            <div className="product-search">
              <Form>
                <div className="form-group m-0">
                  <Input className="form-control" type="text" placeholder="Search.."
                    defaultValue={searchKeyword}
                    onChange={(e) => handleSearchKeyword(e.target.value)} />
                  <i className="fa fa-search"></i>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Fragment >
  );
};
export default ProductSidebar;