import React, { Fragment } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import AnimalImageSlider from "./AnimalImageSlider";
import AnimalDetails from "./AnimalDetails";
import AnimalProperties from "./AnimalProperties";


const AnimalsDetailsPageContain = () => {
  return (
    <Fragment>
      <Container fluid={true}>
        <div>
          <Row className="product-page-main p-0">
            <Col xl="8" md="6" className="box-col-7 xl-50">
              <Card>
                <CardBody>
                  <Row>
                    <AnimalImageSlider />
                  </Row>
                </CardBody>
              </Card>
            </Col>
            
            <AnimalProperties />
          </Row>
        </div>

        <Card>
          <Row className="product-page-main">
          <AnimalDetails />
          </Row>
        </Card>
      </Container>
    </Fragment>
  );
};

export default AnimalsDetailsPageContain;
