import React, { Fragment } from 'react';
import { FormGroup, Label } from 'reactstrap';

const SupplierDetails = (props) => {

    const { SupplierNameLabel = '', register = '', errors = '', } = props;

    return (
        <Fragment>
            {
                SupplierNameLabel ?

                    <FormGroup className="mb-3">
                        <Label>Supplier Name</Label>
                        <input className="form-control" type="text" name="supplierName" placeholder="Supplier Name *"
                            {...register('supplierName', { required: true })} />
                        <span style={{ color: 'red' }}>{errors.supplierName && 'Supplier Name is required'} </span>
                    </FormGroup>
                    :
                    <FormGroup>
                        <Label>Supplier Phone Number </Label>
                        <input className="form-control" type="text" name="supplierPhoneNumber" placeholder="Enter supplier mobile number" {...register('supplierPhoneNumber', { required: true })} />
                        <span style={{ color: 'red' }}>{errors.animalAskingPrice && 'Supplier mobile is required'} </span>
                    </FormGroup>
            }
        </Fragment>
    );
};

export default SupplierDetails;