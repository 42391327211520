import React, { Fragment, useContext } from "react";
import { useForm } from "react-hook-form";
import { Lock, Mail } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { Form, FormGroup, Label } from "reactstrap";
import { Btn, H4, H5, H6 } from "../../../AbstractElements";
import {
  EmailAddress,
  ForgotPassword,
  Login,
  Password,
  SignIn,
} from "../../../Constant";
import UserContext from "../../../_helper/User";

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { loginWithEmail } = useContext(UserContext);

  const navigate = useNavigate();
  const login = async (loginDetails) => {
    const result = await loginWithEmail(loginDetails);

    console.log(result);
    // let timerInterval;

    // if (result.success === true) {
    //   navigate(`${process.env.PUBLIC_URL}`);
    // } else {
    //   SweetAlert.fire({
    //     title: "Login Failed !",
    //     html: "I will close in <b></b> milliseconds.",
    //     timer: 2000,
    //     timerProgressBar: true,
    //     didOpen: () => {
    //       SweetAlert.showLoading();
    //       const timer = SweetAlert.getPopup().querySelector("b");
    //       timerInterval = setInterval(() => {
    //         timer.textContent = `${SweetAlert.getTimerLeft()}`;
    //       }, 100);
    //     },
    //     willClose: () => {
    //       clearInterval(timerInterval);
    //     },
    //   }).then((result) => {
    //     /* Read more about handling dismissals below */
    //     if (result.dismiss === SweetAlert.DismissReason.timer) {
    //       console.log("I was closed by the timer");
    //     }
    //   });
    // }
  };

  return (
    <Fragment>
      <Form className="theme-form login-form" onSubmit={handleSubmit(login)}>
        <div className="login-header text-center">
          <H4>{Login}</H4>
          <H6>Welcome back! Log in to your account.</H6>
        </div>

        <div className="login-social-title">
          <H5>Sign in with Email</H5>
        </div>
        <FormGroup>
          <Label>{EmailAddress}</Label>
          <div className="input-group mb-2">
            <span className="input-group-text">
              <Mail />
            </span>

            <input
              className="form-control"
              type="email"
              placeholder="test@gmail.com"
              {...register("email", { required: true })}
            />
          </div>
          <span style={{ color: "red" }}>
            {errors.email && "Email is required"}{" "}
          </span>
        </FormGroup>
        <FormGroup>
          <Label>{Password}</Label>
          <div className="input-group mb-2">
            <span className="input-group-text">
              <Lock />
            </span>
            <input
              className="form-control"
              type="password"
              placeholder="*********"
              {...register("password", { required: true })}
            />
          </div>
          <span style={{ color: "red" }}>
            {errors.password && "Password is required"}{" "}
          </span>
        </FormGroup>
        <FormGroup className="login-btn form-group">
          <Link to={`${process.env.PUBLIC_URL}/forget-pwd`} className="link">
            {ForgotPassword}?
          </Link>
          <div className="checkbox"></div>
        </FormGroup>
        <FormGroup>
          <Btn
            attrBtn={{
              className: "btn-block",
              color: "primary",
              type: "submit",
            }}
          >
            {SignIn}
          </Btn>
        </FormGroup>
      </Form>
    </Fragment>
  );
};

export default LoginForm;
