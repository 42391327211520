import React, { Fragment, useState } from "react";
import { FormGroup, Label } from "reactstrap";
import DatePicker from "react-datepicker";

const MainDates = (props) => {
  const {
    register = "",
    AnimalDispatchDateLabel = "",
    EndingDate = "",
    errors =''
  } = props;
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());

  return (
    <Fragment>
      {AnimalDispatchDateLabel ? (
        <FormGroup>
          <Label>{AnimalDispatchDateLabel}</Label>
          <input className="form-control" type="date"  name="animalDispatchDate" placeholder="Animal dispatch date *"
                                {...register("animalDispatchDate", { required: true })} />
          <span style={{ color: 'red' }}>{errors.animalDisptachDate && 'Animal dispatch date is required'} </span>
        </FormGroup>
      ) : (
        <FormGroup>
          <Label>{EndingDate}</Label>
          <DatePicker
            className="datepicker-here form-control"
            selected={endDate}
            endDate={endDate}
            
          />
        </FormGroup>
      )}
    </Fragment>
  );
};
export default MainDates;
