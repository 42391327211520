import firebase from "firebase/compat/app";
import React, { Fragment, useContext, useState } from "react";
import Dropzone from "react-dropzone-uploader";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import { Btn, Spinner } from "../../../AbstractElements";
import { objectStorage } from "../../../Config/Config";
import {
  Add,
  AnimalAgeLabel,
  AnimalBirthYearsLabel,
  AnimalBreedLabel,
  AnimalCollectionLocationLabel,
  AnimalColorLabel,
  AnimalDescriptionLabel,
  AnimalDispatchDateLabel,
  AnimalNameLabel,
  AnimalSicknessLabel,
  AnimalTypeLabel,
  AnimalWeightLabel,
  Cancel,
  StartingDate,
  animalAskingPriceLabel,
  SupplierNameLabel,
} from "../../../Constant";
import AnimalContext from "../../../_helper/Animal";

import AnimalCollectionDetails from "./AnimalCollectionDetails";
import AnimalFurtherDetails from "./AnimalFurtherDetails";
import MainDates from "./MainDates";
import MainPriority from "./MainPriority";
import MainTitle from "./MainTitle";
import MainType from "./MainType";
import SupplierDetails from "./SupplierDetails";

const MainList = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const history = useNavigate();

  const { addNewAnimal, setSelectedAnimal } = useContext(AnimalContext);
  const [animalType, setAnimalType] = useState("cattle");
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadFileMetadata, setUploadFileMetadata] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === "done") {
      setUploadFile(file);
      setUploadFileMetadata({
        name: meta.name,
        contentType: meta.type,
        size: meta.size,
      });
    }
  };

  const AddAnimal = (animal) => {
    setLoading(true);

    console.log(animal)

    const storageRef = objectStorage.ref();
    // Generate a unique filename

    const filename = `${animalType}_${uploadFileMetadata.name
      .toString()
      .toLowerCase()}`;
    const uploadTask = storageRef
      .child(`animals/${filename}`)
      .put(uploadFile, uploadFileMetadata);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      () => {},
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;
          // ...
          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          const animalData = {
            ...animal,
            img: downloadURL,
            imageDownloadUrl: downloadURL,
            available: true,
            auctioned: false,
            status: "available",
            variants: [
              downloadURL,
              "https://firebasestorage.googleapis.com/v0/b/livestock-marketplace.appspot.com/o/animals%2Fcattle_20211110_104000.jpg?alt=media&token=07d93b78-62fb-4965-9f20-009a588cf52f",
              "https://firebasestorage.googleapis.com/v0/b/livestock-marketplace.appspot.com/o/animals%2Fcattle_20211110_104000.jpg?alt=media&token=07d93b78-62fb-4965-9f20-009a588cf52f",
              "https://firebasestorage.googleapis.com/v0/b/livestock-marketplace.appspot.com/o/animals%2Fcattle_20211110_104000.jpg?alt=media&token=07d93b78-62fb-4965-9f20-009a588cf52f",
              "https://firebasestorage.googleapis.com/v0/b/livestock-marketplace.appspot.com/o/animals%2Fcattle_20211110_104000.jpg?alt=media&token=07d93b78-62fb-4965-9f20-009a588cf52f",
            ],
          };

          addNewAnimal(animalData);

          setLoading(false);
          // history(`${process.env.PUBLIC_URL}/animals/all`);
        });
      }
    );
  };

  const updateAnimalType = (e) => {
    setAnimalType(e.target.value);
  };

  return (
    <Fragment>
      {loading ? (
        <div className="loader-box">
          <Spinner attrSpinner={{ className: "loader-30" }} />
        </div>
      ) : (
        <Form className="theme-form" onSubmit={handleSubmit(AddAnimal)}>
          <Row>
            <Col sm={8}>
              <MainTitle
                AnimalNameLabel={AnimalNameLabel}
                register={register}
                errors={errors}
              />
            </Col>
            <Col sm={4}>
              <MainTitle
                animalAskingPriceLabel={animalAskingPriceLabel}
                register={register}
                errors={errors}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <SupplierDetails
                SupplierNameLabel={SupplierNameLabel}
                register={register}
                errors={errors}
              />
            </Col>
            <Col sm={4}>
              <SupplierDetails register={register} errors={errors} />
            </Col>
          </Row>
          <Row>
            <MainPriority
              register={register}
              AnimalBreedLabel={AnimalBreedLabel}
              animalType={animalType}
              errors={errors}
            />
            <Col sm="4">
              <MainType
                AnimalTypeLabel={AnimalTypeLabel}
                register={register}
                updateAnimalType={updateAnimalType}
                errors={errors}
              />
            </Col>
            <MainPriority
              register={register}
              AnimalAgeLabel={AnimalAgeLabel}
              animalType={animalType}
              errors={errors}
            />
          </Row>
          <Row>
            <Col sm="4">
              <MainType
                errors={errors}
                register={register}
                AnimalBirthYearsLabel={AnimalBirthYearsLabel}
              />
            </Col>
            <Col sm="4">
              <AnimalFurtherDetails
                errors={errors}
                register={register}
                AnimalSicknessLabel={AnimalSicknessLabel}
              />
            </Col>
            <Col sm="4">
              <MainDates
                StartingDate={StartingDate}
                errors={errors}
                AnimalDispatchDateLabel={AnimalDispatchDateLabel}
                register={register}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <AnimalCollectionDetails
                errors={errors}
                register={register}
                AnimalCollectionLocationLabel={AnimalCollectionLocationLabel}
              />
            </Col>
            <Col sm="4">
              <AnimalCollectionDetails
                errors={errors}
                register={register}
                AnimalWeightLabel={AnimalWeightLabel}
              />
            </Col>
            <Col sm="4">
              <AnimalFurtherDetails
                errors={errors}
                register={register}
                AnimalColorLabel={AnimalColorLabel}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label>{AnimalDescriptionLabel}</Label>
                <input
                  type="textarea"
                  className="form-control"
                  name="description"
                  rows="3"
                  style={{ height: "5rem" }}
                  {...register("description", { required: false })}
                />
                <span style={{ color: "red" }}>
                  {errors.description && "Some Details is required"}
                </span>
              </div>
            </Col>
          </Row>
          {/* <DropItem  setAnimalImage={setAnimalImage}/> */}
          <Row>
            <Col>
              <div className="mb-3">
                <FormGroup>
                  <Label>Upload Animal Image</Label>
                  <Dropzone
                    className="dropzone"
                    onChangeStatus={handleChangeStatus}
                    maxFiles={1}
                    multiple={false}
                    canCancel={false}
                    inputContent="Drop Animal Picture Here"
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-end">
              <div className="mb-0">
                <Btn attrBtn={{ color: "secondary", className: "me-3" }}>
                  {Add}
                </Btn>
                <Btn attrBtn={{ color: "danger" }}>{Cancel}</Btn>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Fragment>
  );
};
export default MainList;
