import React, { Fragment, useContext, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { AnimalListDecription } from "../../../Constant";
import { AnimalListSchema } from "../../../Data/Animals/animalListTableSchema";
import AnimalContext from "../../../_helper/Animal";
import { DatatableElementRow } from "../../../CommonElements/DatatableElementRow";
import { H5 } from "../../../AbstractElements";

const AnimalListContain = () => {
  const { allAnimals, pickSelectedItem, handleFetchData } = useContext(AnimalContext);
  const navigate = useNavigate();

  useEffect(() => {
    handleFetchData();
  }, []);

  const renderAnimalList =
    allAnimals.length === 0
      ? []
      : allAnimals.map((animal) => DatatableElementRow(animal));

  const handleAnimalClickedRow = (animalRow, event) => {
    pickSelectedItem(animalRow.id);
    navigate(decodeURI(`${process.env.PUBLIC_URL}/animals/${animalRow.name}`));
  };

  return (
    <Fragment>
      <Container fluid={true} className="list-products">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Animal List</H5>
                <span>{AnimalListDecription}</span>
              </CardHeader>
              <CardBody>
                <div className="table-responsive product-table">
                  <DataTable
                    noHeader
                    pagination
                    paginationServer
                    columns={AnimalListSchema}
                    data={renderAnimalList}
                    onRowDoubleClicked={handleAnimalClickedRow}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AnimalListContain;
