import Default from '../Pages/DashBoard/Default/Default';
import Ecommerce from '../Pages/DashBoard/Ecommerce/index';

import OrderHistory from '../Pages/Ecommerce/OrderHistory';
import PaymentDetails from '../Pages/Ecommerce/PaymentDetails';
import ProductList from '../Pages/Ecommerce/ProductList';
import ProductPage from '../Pages/Ecommerce/ProductPage';
import Products from '../Pages/Ecommerce/Products';
import FileManager from '../Pages/FileManager';
import KanbanBoard from '../Pages/KanbanBoard';
import CreateList from '../Pages/Project/Create List';

import CreateAnimal from '../Pages/Animal/Add Animal';
import AnimalList from '../Pages/Animal/AnimalList'

import ProjectList from '../Pages/Project/Project List';
import Invoice from '../Pages/Ecommerce/Invoice/index';
import ProductCart from '../Pages/Ecommerce/ProductCart';
import WishList from '../Pages/Ecommerce/Wishlist';
import CheckOut from '../Pages/Ecommerce/CheckOut';
import PricingMembership from '../Pages/Ecommerce/PricingMemberShip';

import ChatApp from '../Pages/Chat/ChatApp';
import VideoChat from '../Pages/Chat/VideoChat';
import UsersProfile from '../Pages/Users/UsersProfile';
import UsersEdit from '../Pages/Users/UsersEdit';
import UsersCardss from '../Pages/Users/UsersCards';
import SocialApp from '../Pages/SocialApp';

import Calender from '../Pages/Calender/Index';

import Draggable from '../Pages/Calender/Draggable Calender';

import Todo from '../Pages/Todo';
import TodoFirebase from '../Pages/Todo Firebase';
import Tasks from '../Pages/Tasks';
import Contact from '../Pages/Contact';
import ContactFirebase from '../Pages/Contact/contactFirebase';
import KnowledgeBase from '../Pages/Knowledgebase/KnowledgeBase';
import KnowledgeCategory from '../Pages/Knowledgebase/KnowledgeCategory';
import KnowledgeDetails from '../Pages/Knowledgebase/KnowledgeDetails';

import LeafletMap from '../Pages/Maps/LeafletMap';
import MapJs from '../Pages/Maps/MapJs';
import SupportTickit from '../Pages/Support Tickit';

import GalleryGrid from '../Pages/Gallery/GalleryGrid';
import GalleryGridDesc from '../Pages/Gallery/GalleryGridDesc';
import MasonryGallery from '../Pages/Gallery/MasonryGallery';
import MasonryGalleryDesc from '../Pages/Gallery/MasonryGalleryDesc';
import HoverEffects from '../Pages/Gallery/HoverEffect';

import AnimalsDetailsPage from '../Pages/Animal/AnimalDetailsPage';

export const routes = [
    { path: `${process.env.PUBLIC_URL}/animals/add/`, Component: <CreateAnimal /> },
    { path: `${process.env.PUBLIC_URL}/animals/:animalName/`, Component: <AnimalsDetailsPage /> },
    { path: `${process.env.PUBLIC_URL}/animals/all/`, Component: <AnimalList /> },
    { path: `${process.env.PUBLIC_URL}/dashboard/default/`, Component: <Default /> },
    { path: `${process.env.PUBLIC_URL}/dashboard/ecommerce/`, Component: <Ecommerce /> },
    { path: `${process.env.PUBLIC_URL}/project/projectlist/`, Component: <ProjectList /> },
    { path: `${process.env.PUBLIC_URL}/project/createlist/`, Component: <CreateList /> },

    // TODO:: TO DELETE BELOW
    { path: `${process.env.PUBLIC_URL}/filemanager`, Component: <FileManager /> },

    { path: `${process.env.PUBLIC_URL}/kanbanboard`, Component: <KanbanBoard /> },

    { path: `${process.env.PUBLIC_URL}/ecommerce/product`, Component: <Products /> },
    { path: `${process.env.PUBLIC_URL}/ecommerce/productpage`, Component: <ProductPage /> },
    { path: `${process.env.PUBLIC_URL}/ecommerce/productlist`, Component: <ProductList /> },
    { path: `${process.env.PUBLIC_URL}/ecommerce/paymentdetails`, Component: <PaymentDetails /> },
    { path: `${process.env.PUBLIC_URL}/ecommerce/orderhistory`, Component: <OrderHistory /> },
    { path: `${process.env.PUBLIC_URL}/ecommerce/invoice`, Component: <Invoice /> },
    { path: `${process.env.PUBLIC_URL}/ecommerce/cart`, Component: <ProductCart /> },
    { path: `${process.env.PUBLIC_URL}/ecommerce/wishlist`, Component: <WishList /> },
    { path: `${process.env.PUBLIC_URL}/ecommerce/checkout`, Component: <CheckOut /> },
    { path: `${process.env.PUBLIC_URL}/ecommerce/pricing`, Component: <PricingMembership /> },


    { path: `${process.env.PUBLIC_URL}/chat/chatapp`, Component: <ChatApp /> },
    { path: `${process.env.PUBLIC_URL}/chat/videocall`, Component: <VideoChat /> },

    { path: `${process.env.PUBLIC_URL}/users/userprofile`, Component: <UsersProfile /> },
    { path: `${process.env.PUBLIC_URL}/users/useredit`, Component: <UsersEdit /> },
    { path: `${process.env.PUBLIC_URL}/users/usercard`, Component: <UsersCardss /> },


    { path: `${process.env.PUBLIC_URL}/social-app`, Component: <SocialApp /> },

    { path: `${process.env.PUBLIC_URL}/calandar/basic-calandar`, Component: <Calender /> },
    { path: `${process.env.PUBLIC_URL}/calandar/draggable-calandar`, Component: <Draggable /> },

    { path: `${process.env.PUBLIC_URL}/todo`, Component: <Todo /> },
    { path: `${process.env.PUBLIC_URL}/todofirebase`, Component: <TodoFirebase /> },

    { path: `${process.env.PUBLIC_URL}/tasks`, Component: <Tasks /> },

    { path: `${process.env.PUBLIC_URL}/contacts`, Component: <Contact /> },
    { path: `${process.env.PUBLIC_URL}/contacts/firebase`, Component: <ContactFirebase /> },

    { path: `${process.env.PUBLIC_URL}/knowledgebase`, Component: <KnowledgeBase /> },
    {
        path: `${process.env.PUBLIC_URL}/knowledgebase/knowledge-category`, Component: <KnowledgeCategory />
    },
    {
        path: `${process.env.PUBLIC_URL}/knowledgebase/knowledge-details`, Component: <KnowledgeDetails />
    },

    {
        path: `${process.env.PUBLIC_URL}/mapsjs`, Component: <MapJs />
    },
    {
        path: `${process.env.PUBLIC_URL}/leafletmap`, Component: <LeafletMap />
    },

    {
        path: `${process.env.PUBLIC_URL}/support-ticket`, Component: <SupportTickit />
    },

    {
        path: `${process.env.PUBLIC_URL}/gallery/imageGallery`, Component: <GalleryGrid />
    },
    {
        path: `${process.env.PUBLIC_URL}/gallery/imageWithDesc`, Component: <GalleryGridDesc />
    },
    {
        path: `${process.env.PUBLIC_URL}/gallery/mesonryGallery`, Component: <MasonryGallery />
    },
    {
        path: `${process.env.PUBLIC_URL}/gallery`, Component: <MasonryGalleryDesc />
    },
    {
        path: `${process.env.PUBLIC_URL}/gallery/imageHover`, Component: <HoverEffects />
    },

];