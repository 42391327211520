export const AnimalListSchema = [
  {
    name: "Image",
    selector: (row) => row.image,
    sortable: false,
    center: "true",
  },
  {
    name: "Details",
    selector: (row) => row.Details,
    sortable: true,
    center: "true",
    wrap: true,
  },
  {
    name: "Ask Amount(USD)",
    selector: (row) => row.ask,
    sortable: true,
    center: "true",
  },
  {
    name: "Collection Address",
    selector: (row) => row.collection_address,
    sortable: true,
    center: "true",
  },
  {
    name: "Stock",
    selector: (row) => row.stock,
    sortable: true,
    center: "true",
  },
  {
    name: "Dispatch Date",
    selector: (row) => row.dispatch_date,
    sortable: true,
    center: "true",
  },
  {
    name: "Weight",
    selector: (row) => row.weight,
    sortable: true,
    center: "true",
  },
  {
    name: "Age",
    selector: (row) => row.age,
    sortable: true,
    center: "true",
  },
  {
    name: "Color",
    selector: (row) => row.color,
    sortable: true,
    center: "true",
  },
  {
    name: "Sickness",
    selector: (row) => row.sickness,
    sortable: true,
    center: "true",
  },
];
