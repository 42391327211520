import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import CreateListContain from '../../../Component/Animals/Create List';

const AddAnimal = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Animals" title="Add Animal" />
      <CreateListContain />
    </Fragment>
  );
};

export default AddAnimal;