import {CalanderSvg, ChartsSvg, ChatSvg, ContactSvg, EcommerceSvg, FilemanagerSvg, GallarySvg, HeaderBookmarkSvg, HomeSvg, JobsearchSvg, KanbanSvg, KnowledgebaseSvg, LearningSvg, MapsSvg, OthersSvg, ProjectSvg, SamplePageSvg, SearchResultSvg, SocialappSvg, SupportTicketSvg, TablesSvg, TaskSvg, TodoSvg, UsersComponentSvg } from '../../Data/svgIcons';

export const MENUITEMS = [
    {
        menutitle: 'DashBoards',
        Items: [
            {
                title: 'Dashboards', icon: HomeSvg, type: 'sub', badge: true, active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/dashboard/default`, title: 'Default', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`, title: 'Ecommerce', type: 'link' },
                ]
            },
        ]
    },
    {
        menutitle: 'Project',
        Items: [
            {
                title: 'Project', icon: ProjectSvg, type: 'sub', badge1: true, active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/project/projectlist`, title: 'Project list', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/project/createlist`, title: 'Create list', type: 'link' },
                ]
            },
        ]
    },
    {
        menutitle: 'Animals',
        Items: [
            {
                title: 'Animals', icon: LearningSvg, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/animals/all`, title: 'Animals list', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/animals/add`, title: 'Add Animals', type: 'link' },
                ]
            },
        ]
    },
    {
        menutitle: 'File Manager',
        Items: [
            { path: `${process.env.PUBLIC_URL}/filemanager`, bookmark: true, icon: FilemanagerSvg, title: 'File Manager', type: 'link' }
        ]
    },
    {
        menutitle: 'Kanban Board',
        Items: [
            { path: `${process.env.PUBLIC_URL}/kanbanboard`, badge2: true, icon: KanbanSvg, title: 'Kanban Board', type: 'link' }
        ]
    },
    {
        menutitle: 'Ecommerce',
        Items: [
            {
                title: 'Ecommerce', icon: EcommerceSvg, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/ecommerce/product`, title: 'Product', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/ecommerce/productpage`, title: 'Product Page', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/ecommerce/productlist`, title: 'Product List', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/ecommerce/paymentdetails`, title: 'Payment Detail', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/ecommerce/orderhistory`, title: 'Order History', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/ecommerce/invoice`, title: 'Invoice', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/ecommerce/cart`, title: 'Cart', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/ecommerce/wishlist`, title: 'Wishlist', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/ecommerce/checkout`, title: 'Checkout', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/ecommerce/pricing`, title: 'Pricing', type: 'link' },
                ]
            },
        ]
    },
    {
        menutitle: 'Chat',
        Items: [
            {
                title: 'Chat', icon: ChatSvg, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/chat/chatapp`, title: 'ChatApp', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/chat/videocall`, title: 'VideoCall', type: 'link' },
                ]
            },
        ]
    },
    {
        menutitle: 'Users',
        Items: [
            {
                title: 'Users', icon: UsersComponentSvg, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/users/userprofile`, title: 'User Profile', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/users/useredit`, title: 'User Edit', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/users/usercard`, title: 'User Cards', type: 'link' },
                ]
            },
        ]
    },
    {
        menutitle: 'Contacts',
        Items: [
            {
                title: 'Contacts', icon: ContactSvg, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/contacts`, type: 'link', title: 'Contacts' },
                    { path: `${process.env.PUBLIC_URL}/contacts/firebase`, type: 'link', title: 'Contact FireBase' },
                ]
            }
        ]
    },
    {
        menutitle: 'Tasks',
        Items: [
            { path: `${process.env.PUBLIC_URL}/tasks`, bookmark: true, icon: TaskSvg, title: 'Tasks', type: 'link' }
        ]
    },
    {
        menutitle: 'Calander',
        Items: [
            {
                title: 'Calander', icon: CalanderSvg, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/calandar/basic-calandar`, type: 'link', title: 'Calander' },
                    { path: `${process.env.PUBLIC_URL}/calandar/draggable-calandar`, type: 'link', title: 'Draggable' },
                ]
            }
        ]
    },
    {
        menutitle: 'Social App',
        Items: [
            { path: `${process.env.PUBLIC_URL}/social-app`, bookmark: true, icon: SocialappSvg, title: 'Social App', type: 'link' }
        ]
    },
    {
        menutitle: 'To-Do',
        Items: [
            {
                title: 'To-Do', icon: TodoSvg, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/todo`, title: 'To-Do', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/todofirebase`, title: 'To-Do Firebase', type: 'link' }
                ]
            }
        ]
    },

    {
        menutitle: 'Gallery',
        Items: [
            {
                title: 'Gallery', icon: GallarySvg, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/gallery/`, title: 'Masonry', type: 'link' },
                ]
            },
        ]
    },
    {
        Items: [
            {
                title: 'Maps', icon: MapsSvg, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/mapsjs`, type: 'link', title: 'Google Map' },
                    { path: `${process.env.PUBLIC_URL}/leafletmap`, type: 'link', title: 'Leaflet Map' },
                ]
            },
        ]
    },
    {
        Items: [
            {
                title: 'Knowledgebase', icon: KnowledgebaseSvg, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/knowledgebase`, type: 'link', title: 'Knowledgebase' },
                    { path: `${process.env.PUBLIC_URL}/knowledgebase/knowledge-category`, type: 'link', title: 'Knowledge Category' },
                    { path: `${process.env.PUBLIC_URL}/knowledgebase/knowledge-details`, type: 'link', title: 'Knowledge details' },
                ]
            },
        ]
    },
    {
        menutitle: 'Support Ticket',
        Items: [
            { path: `${process.env.PUBLIC_URL}/support-ticket`, icon: SupportTicketSvg, type: 'link', active: false, title: 'Support Ticket' },
        ]
    },
];