import React, { Fragment } from "react";
import { FormGroup, Input, Label } from "reactstrap";
// import { FixPrice, Hourly } from '../../../Constant';

const MainType = (props) => {
  const {
    errors = "",
    register = "",
    AnimalBirthYearsLabel = "",
    AnimalTypeLabel = "",
    updateAnimalType,
  } = props;
  return (
    <Fragment>
      {AnimalBirthYearsLabel ? (
        <FormGroup>
          <Label>{AnimalBirthYearsLabel}</Label>
          <input
            className="form-control"
            type="number"
            placeholder="Enter animal age"
            {...register("animalYearsAge", { required: true })}
          />
          <span style={{ color: "red" }}>
            {errors.animalYearsAge && "animal age is required"}
          </span>
        </FormGroup>
      ) : (
        <FormGroup>
          <Label>{AnimalTypeLabel}</Label>
          <select
            placeholder="Select Animal Type"
            className="form-control"
            {...register("animalType", { onChange: updateAnimalType })}
          >
            <option value="cattle">Cattle</option>
            <option value="goats">Goat</option>
            <option value="sheep">Sheep</option>
            <option value="pigs">Pigs</option>
          </select>
          <span style={{ color: "red" }}>
            {errors.animalType && "animal type is required"}
          </span>
        </FormGroup>
      )}
    </Fragment>
  );
};
export default MainType;
