import React, { Fragment } from "react";
import { Col, FormGroup, Input, Label } from "reactstrap";

const AnimalCollectionDetails = (props) => {
  const {
    AnimalCollectionLocationLabel = "",
    register = "",
    errors = "",
    AnimalWeightLabel = "",
  } = props;
  return (
    <Fragment>
      {AnimalCollectionLocationLabel ? (
        <Col>
          <FormGroup className="mb-3">
            <Label>{AnimalCollectionLocationLabel}</Label>
            <input
              className="form-control"
              type="text"
              name="animalCollectionLocation"
              placeholder="Animal Location *"
              {...register("animalCollectionLocation", { required: true })}
            />
            <span style={{ color: "red" }}>
              {errors.animalCollectionLocation && "Animal collection location is required"}{" "}
            </span>
          </FormGroup>
        </Col>
      ) : (
        <Col>
          <FormGroup>
            <Label>{AnimalWeightLabel}</Label>
            <input
              className="form-control"
              min="0"
              type="number"
              name="animalWeight"
              placeholder="Animal Weight (KGs)"
              {...register("animalWeight", { required: true })}
            />
            <span style={{ color: "red" }}>
              {errors.animalWeight && "Animal Weight is required"}{" "}
            </span>
          </FormGroup>
        </Col>
      )}
    </Fragment>
  );
};
export default AnimalCollectionDetails;
