import { dbFirestore, auth } from "../Config/Config";

const customErrorMessages = {
  "auth/wrong-password": "The password is incorrect.",
  "auth/user-not-found": "There is no user with that email address.",
  "auth/email-already-in-use": "The email address is already in use.",
  // ... add more error codes and messages
};

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: "https://dashboard.livestock.co.zw/",
  // This must be true.
  handleCodeInApp: true,
  // dynamicLinkDomain: 'livestock.co.zw'
};

export const signInUser = async (user) => {
  try {
    const docRef = dbFirestore.collection("users").doc(`${user.email}`);
    docRef.get().then((doc) => {
      if (doc.exists) {

        if (doc.get("accessLevel").toString() !== "admin") {
          return {
            error: "Unauthorised Login",
            code: "not allowed",
            success: false,
          };
        }
        
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        return {
          error: "Account doesn't exist",
          code: "not allowed",
          success: false,
        };
      }
    });

    return await auth
      .signInWithEmailAndPassword(user.email, user.password)
      .then((userCredential) => {
        // Signed in
        let user = userCredential.user;
        console.log(user);
        const email = user.email;

        return { success: true, email };
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        

        // Check if you have a custom message
        if (customErrorMessages[errorCode]) {
          errorMessage = customErrorMessages[errorCode];
        }

        return { error: errorMessage, code: errorCode, success: false };
      });
  } catch (er) {
    console.error("Error signing in:", er);
    return { error: "Unexpected error", code: "unknown", success: false };
  }
};

export const loginInWithEmail = async ({ email }) => {
  try {
    return await auth
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        console.log("email successfully sent");

        window.localStorage.setItem("customLivestockSeed", email);

        return { success: true, email };
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);

        // Check if you have a custom message
        if (customErrorMessages[errorCode]) {
          errorMessage = customErrorMessages[errorCode];
        }

        return { error: errorMessage, code: errorCode, success: false };
      });
  } catch (er) {
    console.error("Error signing in:", er);
    return { error: "Unexpected error", code: "unknown", success: false };
  }
};
