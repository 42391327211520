import React, { useState } from "react";
import Context from "./index";
import { signInUser, loginInWithEmail } from "../../Services/AuthServices";

const UserProvider = (props) => {


  const loginUser = async (loginDetails) => {
    const result = await signInUser(loginDetails);
    return result;
  };

  const loginWithEmail = async (loginDetails) => {
    const result = await loginInWithEmail(loginDetails);
    return result;
  };

  return (
    <Context.Provider value={{ loginUser, loginWithEmail }}>{props.children}</Context.Provider>
  );
};

export default UserProvider;
