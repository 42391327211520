import React, { Fragment, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { firebase_app } from '../Config/Config';
import Loader from '../Layout/Loader';
import LayoutRoutes from './LayoutRoutes';
import Callback from '../Auth/Callback';
import { authRoutes } from './AuthRoutes';
import PrivateRoute from './PrivateRoute';
import Signin from '../Component/Pages/Auth/LoginValidation';

const Routers = () => {

  const [currentUser, setCurrentUser] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const jwt_token = localStorage.getItem('token');

  useEffect(() => {

    let abortController = new AbortController();

    firebase_app.auth().onAuthStateChanged(setCurrentUser);

    setAuthenticated(JSON.parse(localStorage.getItem('authenticated')));
    console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
    console.disableYellowBox = true;

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <Fragment>
      <BrowserRouter basename={'/'}>
        <>
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path={'/'} element={<PrivateRoute />}>
                {currentUser !== null && currentUser !== false ?

                  <>
                    <Route exact
                      path={`${process.env.PUBLIC_URL}`}
                      element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/default`} />}
                    />
                    <Route exact
                      path={`/`}
                      element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/default`} />}
                    />
                    <Route path={`/*`} element={<LayoutRoutes />} />
                  </> : <>
                    <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/login`} />} />
                    <Route exact path={`/`} element={<Navigate to={`${process.env.PUBLIC_URL}/login`} />} />
                  </>}
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/callback`} element={<Callback />} />
              <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<Signin />} />
              {authRoutes.map(({ path, Component }, i) => (
                <Route path={path} element={Component} key={i} />
              ))}
            </Routes>
          </Suspense>
        </>
      </BrowserRouter>
    </Fragment >
  );
};
export default Routers;