import React, { Fragment } from 'react';
import GoogleMapsContain from '../../Component/Maps/GoogleMap';

const MapJs = () => {
  return (
    <Fragment>
      <GoogleMapsContain />
    </Fragment>
  );
};
export default MapJs;