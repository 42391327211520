import React, { Fragment, useContext } from 'react';
import { useForm } from "react-hook-form";
import { Mail } from 'react-feather';
import { Link } from 'react-router-dom';
import { Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from 'reactstrap';
import { Btn, H4, P } from '../../../AbstractElements';
import { SignIn, Unlock } from '../../../Constant';
import UserContext from "../../../_helper/User";


const VerifyUserAccount = () => {

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const { loginUser } = useContext(UserContext);

    const verifyAccount = ({email}) => {
        console.log(email);
    }

    return (
        <Fragment>
            <section>
                <Container fluid={true} className="p-0">
                    <Row className="m-0">
                        <Col className="p-0">
                            <div className="login-main">
                                <div className='login-card1'>
                                    <Form className="theme-form login-form" onSubmit={handleSubmit(verifyAccount)}>
                                        <H4>{Unlock}</H4>
                                        <FormGroup>
                                            <Label className="col-form-label">Enter your Password</Label>
                                            <InputGroup><span className="input-group-text"><Mail /></span>
                                                <Input className="form-control" type="email" {...register("email", { required: true })} placeholder="email@livestock.co.zw" />
                                                <span style={{ color: "red" }}>
                                                    {errors.email && "Email is required"}{" "}
                                                </span>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'submit' }} > Verify</Btn>
                                        </FormGroup>
                                        <P>Already have an account?
                                            <Link to={`${process.env.PUBLIC_URL}/login`}>
                                                <a className="ms-2" href="#javascritp">{SignIn}</a>
                                            </Link>
                                        </P>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
};

export default VerifyUserAccount;