import React, { Fragment } from "react";
import "./App.css";
import Routers from "./Routes";
import BookmarkProvider from "./_helper/bookmark/BookmarkProvider";
import ChatProvider from "./_helper/chat-app/ChatProvider";
import ContactProvider from "./_helper/Contact/ContactProvider";
import CartProvider from "./_helper/ecommerce/cart/CartProvider";
import FilterProvider from "./_helper/ecommerce/filter/FilterProvider";
import ProductProvider from "./_helper/ecommerce/product/ProductProvider";
import WishListProvider from "./_helper/ecommerce/wishlist/WishProvider";

import ProjectProvider from "./_helper/project-app/ProjectProvider";
import TodoProvider from "./_helper/todo-app/TodoProvider";
import TaskProvider from "./_helper/task-app/TaskProvider";

import CustomProvider from "./_helper/customizer/CustomizerProvider";
import GalleryProvider from "./_helper/Gallery/GalleryProvider";
import TableProvider from "./_helper/Table/TableProvider";
import ChartistProvider from "./_helper/Chartist/ChartistProvider";
import ChartjsProvider from "./_helper/Chartjs/ChartProvider";
import GoogleChartProvider from "./_helper/GoogleChart/GoogleChartProvider";

import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/customizer/CustomizerProvider";
import AnimalProvider from "./_helper/Animal/AnimalProvider";
import UserProvider from "./_helper/User/UserProvider";
function App() {
  return (
    <Fragment>
      <UserProvider>
        <CustomizerProvider>
          <GoogleChartProvider>
            <ChartjsProvider>
              <ChartistProvider>
                <TableProvider>
                  <GalleryProvider>
                    <CustomProvider>
                      <TaskProvider>
                        <ContactProvider>
                          <TodoProvider>
                            <WishListProvider>
                              <BookmarkProvider>
                                <FilterProvider>
                                  <CartProvider>
                                    <ChatProvider>
                                      <ProductProvider>
                                        <AnimalProvider>
                                          <ProjectProvider>
                                            <AnimationThemeProvider>
                                              {" "}
                                              <Routers />
                                            </AnimationThemeProvider>{" "}
                                          </ProjectProvider>
                                        </AnimalProvider>
                                      </ProductProvider>
                                    </ChatProvider>
                                  </CartProvider>
                                </FilterProvider>
                              </BookmarkProvider>
                            </WishListProvider>
                          </TodoProvider>
                        </ContactProvider>
                      </TaskProvider>
                    </CustomProvider>
                  </GalleryProvider>
                </TableProvider>
              </ChartistProvider>
            </ChartjsProvider>
          </GoogleChartProvider>
        </CustomizerProvider>
      </UserProvider>
    </Fragment>
  );
}
export default App;
