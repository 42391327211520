import React, { Fragment } from "react";
import { Col, FormGroup, Label } from "reactstrap";

const AnimalCollectionDetails = (props) => {
  const {
    AnimalColorLabel = "",
    register = "",
    errors = "",
    AnimalSicknessLabel = "",
  } = props;
  return (
    <Fragment>
      {AnimalColorLabel ? (
        <Col>
          <FormGroup className="mb-3">
            <Label>{AnimalColorLabel}</Label>
            <input
              className="form-control"
              type="text"
              name="animalColor"
              placeholder="Animal Color *"
              {...register("animalColor", { required: true })}
            />
            <span style={{ color: "red" }}>
              {errors.animalColor && "Animal color is required"}{" "}
            </span>
          </FormGroup>
        </Col>
      ) : (
        <Col>
          <FormGroup>
            <Label>{AnimalSicknessLabel}</Label>
            <input
              className="form-control"
              type="text"
              name="animalSickness"
              placeholder="Animal Sickness"
              {...register("animalSickness", { required: true })}
            />
            <span style={{ color: "red" }}>
              {errors.animalSickness && "Animal Sickness is required"}{" "}
            </span>
          </FormGroup>
        </Col>
      )}
    </Fragment>
  );
};
export default AnimalCollectionDetails;
