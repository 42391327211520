import React, { Fragment} from 'react'
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import AnimalListContain from "../../../Component/Animals/AnimalsList"


const AnimalList = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Animals" title=" Animal List"/>
            <AnimalListContain />
        </Fragment>
    )
}

export default AnimalList;