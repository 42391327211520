import React, { Fragment } from 'react';
import { FormGroup, Label } from 'reactstrap';

const MainTitle = (props) => {

    const { AnimalNameLabel = '', register = '', errors = '', animalAskingPriceLabel = '' } = props;

    return (
        <Fragment>
            {
                AnimalNameLabel ?

                    <FormGroup className="mb-3">
                        <Label>{AnimalNameLabel}</Label>
                        <input className="form-control" type="text" name="animalName" placeholder="Animal Name *"
                            {...register('animalName', { required: true })} />
                        <span style={{ color: 'red' }}>{errors.animalName && 'Animal Name is required'} </span>
                    </FormGroup>
                    :
                    <FormGroup>
                        <Label>{animalAskingPriceLabel}</Label>
                        <input className="form-control" type="number" name="animalAskingPrice" placeholder="Enter how much you want for animal" {...register('animalAskingPrice', { required: true })} />
                        <span style={{ color: 'red' }}>{errors.animalAskingPrice && 'Animal Asking price is required'} </span>
                    </FormGroup>
            }
        </Fragment>
    );
};
export default MainTitle;