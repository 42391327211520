import React, { useContext, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Row, Col, Card, CardBody, Table } from "reactstrap";
import { Btn, LI, P, UL } from "../../../../AbstractElements";
import AnimalContext from "../../../../_helper/Animal";
import StarRatings from "./StarRating";

const AnimalDetails = () => {
  const { selectedAnimal, symbol, handleRemoveAnimal, handleListAnimal } =
    useContext(AnimalContext);
  const [animalStatus, setAnimalStatus] = useState(selectedAnimal.status);
  const navigate = useNavigate();

  const productss = {
    price: selectedAnimal.animalAskingPrice,
    discountPrice: 0,
    description: selectedAnimal.description,
    status: animalStatus,
    type: selectedAnimal.animalType,
    breed: selectedAnimal.animalBreed,
    availability: selectedAnimal.status,
    seller: selectedAnimal.supplierName,
    sellerPhone: selectedAnimal.supplierNumber,
    color: selectedAnimal.animalColor,
    name: selectedAnimal.animalName,
  };

  const approveAnimalListing = () => {
    handleListAnimal(selectedAnimal.id, selectedAnimal);
    setAnimalStatus("listed")
  };

  const deleteAnimal = () => {
    handleRemoveAnimal(selectedAnimal.id, selectedAnimal);
    
    navigate(`${process.env.PUBLIC_URL}/animals/all`);
  };

  return (
    <Fragment>
      <Col xl="12" className="box-col-12 proorder-xl-3 xl-100">
        <Card>
          <CardBody>
            <div className="pro-group pt-0 border-0">
              <StarRatings animalName={productss.name} />
              <div className="product-price">
                {symbol}
                {productss.price}
                <del>
                  {symbol}
                  {productss.discountPrice}
                </del>
              </div>
              <UL attrUL={{ className: "simple-list product-color flex-row" }}>
                <LI attrLI={{ className: "bg-primary" }}></LI>&nbsp;
                <LI attrLI={{ className: "bg-secondary" }}></LI>&nbsp;
                <LI attrLI={{ className: "bg-success" }}></LI>&nbsp;
                <LI attrLI={{ className: "bg-info" }}></LI>&nbsp;
                <LI attrLI={{ className: "bg-warning" }}></LI>&nbsp;
              </UL>
            </div>
            <div className="pro-group">
              {" "}
              <P>{productss.description}.</P>
            </div>
            <div className="pro-group">
              <Row>
                <Col md="6">
                  <Table borderless>
                    <tbody>
                      <tr>
                        <td>
                          <b>{"Type"} &nbsp;&nbsp;&nbsp;:</b>
                        </td>
                        <td>{productss.type}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>
                            {"Availability"} &nbsp;&nbsp;&nbsp;:
                            &nbsp;&nbsp;&nbsp;
                          </b>
                        </td>
                        <td
                          className={
                            productss.availability === "available"
                              ? "txt-success"
                              : "txt-danger"
                          }
                        >
                          {" "}
                          {animalStatus}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col md="6">
                  <Table borderless>
                    <tbody>
                      <tr>
                        <td>
                          {" "}
                          <b>
                            {"Seller"} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
                          </b>
                        </td>
                        <td>{productss.seller}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>{"Color"}&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;</b>
                        </td>
                        <td>{productss.color}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
            <div className="pro-group pb-0">
              <div className="pro-shop">
                {animalStatus !== "listed" ? (
                  <Btn
                    attrBtn={{
                      color: "primary",
                      className: "btn btn-primary m-r-10",
                      onClick: approveAnimalListing,
                    }}
                  >
                    Approve For Listing
                  </Btn>
                ) : (
                  ""
                )}
                <Btn
                  attrBtn={{
                    color: "secondary",
                    className: "btn btn-danger",
                    onClick: deleteAnimal,
                  }}
                >
                  Delete Animal
                </Btn>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default AnimalDetails;
